import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setAppStatusAC } from '../../bll/reducers/appReducer';
import { isAxiosError } from 'axios';
import { handleServerNetworkError } from '../../common/utils/error-utils';
import { apiCustomer, ResponseCreateBotType } from './API/customersAPI';
import { RootState } from '../../bll/store/store';

export const setBot = createAsyncThunk<ResponseCreateBotType, { token: string; firstName: string; userName: string }>(
  'createBot/setBot',
  async (payload: { token: string; firstName: string; userName: string }, thunkAPI) => {
    thunkAPI.dispatch(setAppStatusAC({ status: 'loading' }));

    const state = thunkAPI.getState() as RootState;

    try {
      const result = await apiCustomer.createBot({ ...payload, customerId: state.customerLogin.customerInfo.id });

      thunkAPI.dispatch(setAppStatusAC({ status: 'succeeded' }));

      return result.data;
    } catch (err) {
      thunkAPI.dispatch(setAddAttemptsAC({ addAttempts: state.createBot.addAttempts + 1 }));
      if (isAxiosError(err)) {
        if (err.response?.status === 400 && err.response?.data.message === 'Bad Request Exception') {
          handleServerNetworkError('Что-то пошло не так', thunkAPI.dispatch);
          return thunkAPI.rejectWithValue(null);
        }
        handleServerNetworkError(err.response?.data.message, thunkAPI.dispatch);
        return thunkAPI.rejectWithValue(null);
      }
      return thunkAPI.rejectWithValue(null);
    }
  }
);
export const changeBotToken = createAsyncThunk(
  'createBot/changeBotToken',
  async (payload: { token: string; firstName: string; userName: string }, thunkAPI) => {
    thunkAPI.dispatch(setAppStatusAC({ status: 'loading' }));
    const state = thunkAPI.getState() as RootState;
    try {
      const result = await apiCustomer.putBotToken(payload);

      thunkAPI.dispatch(setAppStatusAC({ status: 'succeeded' }));
      return payload.token;
    } catch (err) {
      thunkAPI.dispatch(setAddAttemptsAC({ addAttempts: state.createBot.addAttempts + 1 }));
      if (isAxiosError(err)) {
        if (err.response?.status === 400 && err.response?.data.message === 'Bad Request Exception') {
          handleServerNetworkError('Что-то пошло не так', thunkAPI.dispatch);
          return thunkAPI.rejectWithValue(null);
        }
        handleServerNetworkError(err.response?.data.message, thunkAPI.dispatch);
        return thunkAPI.rejectWithValue(null);
      }
      handleServerNetworkError('Такой ключ уже есть в системе', thunkAPI.dispatch);
      return thunkAPI.rejectWithValue({ message: 'Такой ключ уже есть в системе' });
    }
  }
);

export const slice = createSlice({
  name: 'createBot',
  initialState: {
    customer_id: null,
    first_name: null,
    id: null,
    token: null,
    username: null,
    addAttempts: 0,
  } as ResponseCreateBotType,
  reducers: {
    setAddAttemptsAC(state, action: PayloadAction<{ addAttempts: number }>) {
      state.addAttempts = action.payload.addAttempts;
    },
  },
  extraReducers: builder => {
    builder.addCase(setBot.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.id = action.payload.id;
      state.customer_id = action.payload.customer_id;
      state.first_name = action.payload.first_name;
      state.username = action.payload.username;
    });
    builder.addCase(changeBotToken.fulfilled, (state, action) => {
      return {
        ...state,
        token: action.payload,
      };
    });
  },
});

export const { setAddAttemptsAC } = slice.actions;
